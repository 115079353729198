<div class="homev2-container">
	<app-perfilv2 [backgroundImg]="backgroundImg"> </app-perfilv2>
	<div class="home">
		<div class="home-and-projects">
			<div class="carousel-background">
				<div class="title-section">
					<div class="title-section-text">
						<div class="box-label capitalize">
							{{ 'PERFIL.MY_PROJECTS' | translate }}
						</div>
						<div class="box-label-sub">
							{{ 'PERFIL.MY_PROJECTS_TEXT' | translate }}
						</div>
					</div>
					<span class="ver-mais" (click)="abrirMais('projetos')">{{ 'PERFIL.SEE_MORE' | translate }}</span>
				</div>
				<div class="carousel">
					<!-- CARROSSEL DE PROJETOS -->
					<cls-carousel>
						<div class="d-flex projeto">
							<cls-projeto-card
								[data]="projeto"
								[polos]="state?.polos"
								*ngFor="let projeto of projetosBox; let i = index"
								[class.first]="i == 0"
								[class.last]="i == projetosBox.length - 1"
							></cls-projeto-card>
						</div>
					</cls-carousel>
				</div>
				<div *ngIf="!projetosBox || !projetosBox.length" class="no-projects-section">
					<span class="no-projects-section__text">
						{{ 'LOGIN.NO_ACTIVE_PROJECTS' | translate }}
					</span>
					<span class="no-projects-section__text">
						{{ 'LOGIN.NO_ACTIVE_SEE_MORE' | translate }}
					</span>
				</div>
			</div>
			<app-side-menu></app-side-menu>
		</div>

		<!-- Carrossel de eventos -->
		<div class="carousel-background" *ngIf="state?.eventos?.eventosUser?.length > 0">
			<div class="title-section">
				<div class="title-section-text">
					<div class="box-label capitalize">
						{{ 'PERFIL.EVENTS' | translate }}
					</div>
					<div class="box-label-sub">
						{{ 'PERFIL.EVENTS_TEXT' | translate }}
					</div>
				</div>
				<span class="ver-mais" (click)="abrirMais('eventos')">{{ 'PERFIL.SEE_MORE' | translate }}</span>
			</div>
			<div class="carousel">
				<!-- CARROSSEL DE EVENTOS -->
				<cls-carousel>
					<div class="d-flex eventos">
						<cls-eventos-box
							[data]="evento"
							*ngFor="let evento of eventosNormalized; let i = index"
							[class.first]="i == 0"
							[class.last]="i == eventosNormalized.length - 1"
						></cls-eventos-box>
					</div>
				</cls-carousel>
			</div>
		</div>

		<!-- carrossel de conteudos -->
		<div class="carousel-background" *ngIf="state?.conteudos?.length">
			<div class="title-section">
				<div class="title-section-text">
					<div class="box-label capitalize">
						{{ 'PERFIL.CONTENTS' | translate }}
					</div>
					<div class="box-label-sub">
						{{ 'PERFIL.CONTENTS_TEXT' | translate }}
					</div>
				</div>
				<!-- <span class="ver-mais" (click)="abrirMais('conteudos')">{{
          "PERFIL.SEE_MORE" | translate
        }}</span> -->
			</div>
			<div class="carousel">
				<cls-carousel>
					<!-- CARROSSEL DE CONTEÚDOS -->
					<div class="d-flex contents-box">
						<cls-contents-box
							[data]="content"
							*ngFor="let content of contentsBox | orderBy : 'ordem'; let i = index"
							[class.first]="i == 0"
							[class.last]="i == contentsBox?.length - 1"
						></cls-contents-box>
					</div>
				</cls-carousel>
			</div>
		</div>
	</div>

	<cls-modal-video *ngIf="state?.modalVideo" (mousedown)="checkCloseModal($event)"></cls-modal-video>
</div>
