<div class="overlay" (click)="closeModel(); $event.stopPropagation()">
	<div class="box" (click)="$event.stopPropagation()">
		<div class="close-pattern" (click)="closeModel(); $event.stopPropagation()">
			<!-- <i class="fas fa-times pointer"></i> -->
			<img src="assets/icons/fas-fa-times-pointer-gray.svg" />
		</div>
		<div class="content" id="pdfProject" #pdfProject>
			<div class="content-pdf d-flex flex-col" *ngIf="!isLoading; else loading">
				<!-- Primeira Página -->
				<div class="content-pdf__first-page d-flex flex-col align-items-center">
					<img class="content-pdf__first-page__liga-logo" [src]="logoUrl" alt="" srcset="" />
					<!-- <div class="content-pdf__first-page__liga-logo"></div> -->
					<div class="content-pdf__first-page__projeto">
						<div class="content-pdf__first-page__projeto__empresa">
							<p class="content-pdf__first-page__projeto__empresa__title">{{ contentPDF.projeto_info.nome_empresa }}</p>
							<p class="content-pdf__first-page__projeto__empresa__subtitle">
								{{ contentPDF.projeto_info.nivel_ensino }}
							</p>
							<!-- <p class="content-pdf__first-page__projeto__empresa__subtitle">2022.1</p> -->
							<p class="content-pdf__first-page__projeto__empresa__text">
								{{ 'PROJECTS.INITIAL_DATE' | translate }}: {{ contentPDF.projeto_info.data_inicio | date : dateFormat }}
							</p>
							<p class="content-pdf__first-page__projeto__empresa__text">
								{{ 'PROJECTS.FINAL_DATE' | translate }}: {{ contentPDF.projeto_info.data_termino | date : dateFormat }}
							</p>
						</div>
						<div class="content-pdf__first-page__projeto__equipe">
							<p class="content-pdf__first-page__projeto__equipe__title">{{ contentPDF.projeto_info.nome_autor }}</p>
							<p class="content-pdf__first-page__projeto__equipe__subtitle">{{ 'PROJECTS.SUBJECT' | translate }}</p>
							<p class="content-pdf__first-page__projeto__equipe__text">
								{{ contentPDF.projeto_info.nome_disciplina }}
							</p>
							<p class="content-pdf__first-page__projeto__equipe__subtitle">{{ 'PROJECTS.PROJECT' | translate }}</p>
							<p class="content-pdf__first-page__projeto__equipe__text">
								{{ contentPDF.projeto_info.nome_projeto }}
							</p>
							<div class="flex-row">
								<div class="flex-col mr-4">
									<p class="content-pdf__first-page__projeto__equipe__subtitle">{{ 'PROJECTS.TEACHER' | translate }}</p>
									<p class="content-pdf__first-page__projeto__equipe__text">{{ contentPDF.projeto_info.nome_tutor }}</p>
								</div>
								<div class="flex-col">
									<p class="content-pdf__first-page__projeto__equipe__subtitle">
										{{ 'PROJECTS.COORDINATOR' | translate }}
									</p>
									<p
										*ngFor="let coordenador of contentPDF.projeto_coordenadores; let first = first; let last = last"
										class="content-pdf__first-page__projeto__equipe__text flex-row"
									>
										<span *ngIf="first || !last">{{ coordenador }}</span>
										<span *ngIf="!first && last">{{ 'PROJECTS.AND' | translate }} {{ coordenador }}</span>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- Páginas de índice -->
				<div class="content-pdf__index-pages d-flex flex-col">
					<ng-container *ngTemplateOutlet="defaultTitle; context: { $implicit: 'PROJECTS.INDEX' }"></ng-container>
					<div class="flex-row content-pdf__index-pages__section">
						<div class="content-pdf__index-pages__section__index-spacer flex-col"></div>
						<div class="content-pdf__index-pages__section__index-content flex-col">
							<p class="content-pdf__index-pages__section__index-content__title">
								{{ contentPDF.projeto_info.nome_disciplina }}
							</p>
							<p class="content-pdf__index-pages__section__index-content__subtitle">
								{{ contentPDF.projeto_info.nome_projeto }}
							</p>
						</div>
					</div>
					<div *ngFor="let fase of contentPDF.projeto_fases" class="flex-row content-pdf__index-pages__section">
						<ng-container *ngIf="fase.fase_nome !== 'ENTREGAFINAL'">
							<div class="content-pdf__index-pages__section__index-spacer flex-col">
								<p class="content-pdf__index-pages__section__index-spacer__title">
									{{ 'PROJECTS.' + fase.fase_nome | translate }}
								</p>
								<p class="content-pdf__index-pages__section__index-spacer__subtitle">
									{{ 'PROJECTS.ACTIVITIES' | translate }}
								</p>
							</div>
							<div class="content-pdf__index-pages__section__index-content flex-col">
								<div class="content-pdf__index-pages__section__index-content__atividades flex-col">
									<div
										class="flex-row justify-content-between align-items-center mb-2"
										*ngFor="let atividade of fase.fase_atividades; let i = index"
									>
										<div class="mr-3">{{ atividade.atividade_titulo | slice : 0 : 65 }}</div>
										<div class="content-pdf__index-pages__section__index-content__atividades__divider"></div>
										<div class="ml-3">{{ i + 1 }}</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
				<!-- Próximas páginas -->
				<div class="content-pdf__more-pages d-flex flex-col">
					<!-- Nome da disciplina seguido do banner do projeto -->
					<ng-container
						*ngTemplateOutlet="defaultTitle; context: { $implicit: contentPDF.projeto_info.nome_disciplina }"
					>
					</ng-container>
					<div class="flex-row content-pdf__more-pages__projeto-banner">
						<img [src]="contentPDF.projeto_info.foto_capa_web" alt="" />
					</div>

					<!-- Conteudo de cada uma das fases -->
					<div
						*ngFor="let fase of contentPDF.projeto_fases; let i = index"
						[ngClass]="fase.fase_nome !== 'ENTREGAFINAL' ? 'fase' + (i + 1) : ''"
						class="flex-col content-pdf__more-pages__section"
					>
						<ng-container *ngIf="fase.fase_nome !== 'ENTREGAFINAL'">
							<ng-container
								*ngTemplateOutlet="
									faseTitle;
									context: { $implicit: 'PROJECTS.' + fase.fase_nome, entrega: contentPDF.projeto_info.data_inicio }
								"
							>
							</ng-container>
							<!-- Atividades da fase -->
							<div class="flex-row content-pdf__more-pages__section">
								<div class="content-pdf__more-pages__section__spacer-min"></div>
								<div class="content-pdf__more-pages__section__content flex-col">
									<p class="content-pdf__more-pages__section__content__title">
										{{ 'PROJECTS.ACTIVITIES' | translate }}
									</p>
									<div
										*ngFor="let objetivo of fase.fase_objetivos"
										class="content-pdf__more-pages__section__content__subtitle"
									>
										<p>{{ objetivo.objetivo_nome }}</p>
										<div *ngFor="let atividade of objetivo.atividades; let i = index">
											<p class="subtitle-option">{{ i + 1 }}. {{ atividade.atividade_titulo }}</p>
										</div>
									</div>
								</div>
							</div>
							<!-- Detalhes das atividades da fase -->
							<div
								*ngFor="let atividade of fase.fase_atividades; let i = index"
								class="flex-col content-pdf__more-pages__section"
							>
								<div class="flex-row align-items-baseline">
									<div class="content-pdf__more-pages__section__spacer-max">
										<p class="content-pdf__more-pages__section__spacer-max__number">{{ i + 1 }}</p>
									</div>
									<p class="content-pdf__more-pages__section__content__title">{{ atividade.atividade_titulo }}</p>
								</div>
								<div class="flex-row">
									<div class="content-pdf__more-pages__section__spacer-max"></div>
									<div class="content-pdf__more-pages__section__content flex-col">
										<p
											[innerHTML]="atividade.atividade_descricao | sanitizeHtml"
											class="content-pdf__more-pages__section__content__text"
										></p>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
					<!-- Fim do conteúdo das fases -->
					<div *ngIf="entregaFinal" class="flex-col content-pdf__more-pages__section final">
						<ng-container>
							<ng-container
								*ngTemplateOutlet="
									faseTitle;
									context: {
										$implicit: 'PROJECTS.' + entregaFinal.fase_nome,
										entrega: entregaFinal.fase_atividades[0].data_entrega
									}
								"
							>
							</ng-container>
							<!-- Atividades da fase -->
							<div
								*ngFor="let entrega of entregaFinal.fase_atividades"
								class="flex-row content-pdf__more-pages__section"
							>
								<ng-container
									*ngTemplateOutlet="
										faseEntrega;
										context: {
											$implicit: entrega.nome_entrega,
											tentativas: entrega.tentativas,
											tipo: entrega.tipo_avaliacao,
											descricao: entrega.descricao_entrega
										}
									"
								>
								</ng-container>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
		<div class="button-section">
			<button
				*ngIf="!isLoading"
				[printTitle]="contentPDF.projeto_info.nome_projeto"
				printSectionId="pdfProject"
				[useExistingCss]="true"
				ngxPrint
			>
				{{ 'PROJECTS.PRINT' | translate }}
			</button>
		</div>
		<ng-template #loading>
			<div class="spinner-content items-center">
				<app-spinner class="l"></app-spinner>
			</div>
		</ng-template>

		<ng-template #defaultTitle let-name>
			<div class="content-pdf__index-pages__page-title flex-row w-100">
				<div class="content-pdf__index-pages__page-title__tag">
					<p>{{ name | translate }}</p>
				</div>
			</div>
		</ng-template>
		<ng-template #faseTitle let-name let-entrega="entrega">
			<div class="flex-row align-items-center content-pdf__more-pages__section__tag">
				<div class="content-pdf__more-pages__section__tag__spacer"></div>
				<p>
					<span class="content-pdf__more-pages__section__tag__title">{{ name | translate }} </span>
					<span class="content-pdf__more-pages__section__tag__subtitle">
						{{ 'PROJECTS.ASSESSMENT_DAY' | translate }}
						<span *ngIf="entrega">{{ entrega | date : dateFormat }}</span>
						<span *ngIf="!entrega">--/--/--</span>
					</span>
				</p>
			</div>
		</ng-template>
		<ng-template #faseEntrega let-name let-tentativas="tentativas" let-tipo="tipo" let-descricao="descricao">
			<div class="d-flex flex-row w-100">
				<div class="content-pdf__more-pages__section__spacer-min"></div>
				<div class="content-pdf__more-pages__section__entrega flex-col">
					<p class="content-pdf__more-pages__section__entrega__title">
						{{ 'PROJECTS.EVALUATION_TITLE' | translate }}
					</p>
					<p>{{ 'PROJECTS.EVALUATION_TYPE' | translate }}{{ tipo }}</p>
					<p>{{ 'PROJECTS.ATTEMPT' | translate }}: {{ tentativas }}</p>
					<p>{{ name }}</p>
					<p class="content-pdf__more-pages__section__entrega__type">
						{{ tipo }} {{ 'PROJECTS.DELIVERED_BY' | translate }}
					</p>
				</div>
			</div>
		</ng-template>
	</div>
</div>
