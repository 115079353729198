import { Component, EventEmitter, Input, Output } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { GlobalService } from 'src/app/services/global-service/global.service';

@Component({
	selector: 'cls-resposta-avaliacao-quiz-novo',
	templateUrl: './resposta-avaliacao-quiz-novo.component.html',
	styleUrls: ['./resposta-avaliacao-quiz-novo.component.scss'],
})
export class RespostaAvaliacaoQuizNovoComponent {
	@Input() index;
	@Input() questao;
	@Input() total;
	@Input() timer;
	@Input() savedAnswer;
	@Input() hasBack = true;

	@Output() next = new EventEmitter();
	@Output() before = new EventEmitter();
	@Output() revision = new EventEmitter();
	@Output() changedAnswer = new EventEmitter();
	@Output() exit = new EventEmitter();

	answer = [];
	bars = [];
	selectedOption = null;
	isOpenModal = false;

	constructor(public global: GlobalService) {}

	ngOnChanges() {
		this.selectedOption = null;
		this.mountBars();
		this.questao = JSON.parse(JSON.stringify(this.questao));
		this.savedAnswer = this.questao.respostas.find((resposta) => resposta.marcada);
		if (this.savedAnswer) this.selectedOption = this.savedAnswer;
	}

	markAnswer(option) {
		if (this.questao.id_quiz_entrega_turma_tipo === 1) {
			const alternativaAnterior = this.questao.respostas.find(
				(resposta) =>
					resposta.id_avaliacao_estudante_tentativa_quiz_resposta ===
					this.selectedOption?.id_avaliacao_estudante_tentativa_quiz_resposta
			);

			if (alternativaAnterior) {
				if (
					alternativaAnterior.id_avaliacao_estudante_tentativa_quiz_resposta !==
					option.id_avaliacao_estudante_tentativa_quiz_resposta
				) {
					alternativaAnterior.marcada = false;
					option.marcada = !option.marcada;
				}
			} else {
				option.marcada = !option.marcada;
			}
		} else {
			option.marcada = !option.marcada;
		}

		this.selectedOption = option;
		this.emitAnswer();
	}

	mountBars() {
		this.bars = [];
		for (let i = 0; i < this.total; i++) {
			if (i < this.index) this.bars.push(true);
			else this.bars.push(false);
		}
	}

	emitNext() {
		this.next.emit(true);
	}

	emitBefore() {
		this.before.emit(true);
	}

	emitRevision() {
		this.revision.emit(true);
	}

	emitAnswer() {
		this.changedAnswer.emit({
			questao: this.questao,
			answer: this.selectedOption,
		});
	}

	emitExit() {
		this.exit.emit(true);
	}

	openModal(): void {
		if (this.questao.id_quiz_entrega_turma_tipo === 3) {
			this.changedAnswer.emit({
				questao: this.questao,
				answer: 'ranking',
			});
			this.confirmModal();
		} else {
			if (!this.savedAnswer) this.isOpenModal = true;
			else this.confirmModal();
		}
	}

	closeModal(): void {
		this.isOpenModal = false;
	}

	confirmModal(): void {
		if (this.index === this.total) this.emitRevision();
		else this.emitNext();
	}

	dropAlternativaRanking(event: any, respostas: any): void {
		const respostasTemp = respostas;
		moveItemInArray(respostasTemp, event.previousIndex, event.currentIndex);
		respostasTemp.forEach((resposta: any, index: number) => (resposta.ordenacao = index + 1));
		respostas = respostasTemp;
	}
}
