import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { HeaderBase } from 'projects/ui/src/lib/core/classes-abstratas/header-base';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificacaoMensagemTratadaModel } from 'src/app/models/notificacao-mensagem.model';
import { DataService } from 'src/app/services/data-service/data.service';
import { GlobalService } from 'src/app/services/global-service/global.service';
import { NotificacaoService } from 'src/app/services/notificacao/notificacao.service';
import { AppState } from 'src/app/state';
import {
	fillConteudos,
	fillEventos,
	fillLastRoute,
	fillModalVideo,
	fillProjetosAluno,
	quantidadeCompetencias,
} from 'src/app/state/state.actions';

@Component({
	selector: 'app-homev2',
	templateUrl: './homev2.component.html',
	styleUrls: ['./homev2.component.scss'],
})
export class Homev2Component extends HeaderBase implements OnInit, OnDestroy {
	stateSubscription: Subscription;
	state: AppState = {} as AppState;
	filtereds = [];
	backgroundImg;
	quantidadeDeCompetencias: number;
	notificacoes: NotificacaoMensagemTratadaModel;
	areaTipo = [];
	subscriptions: Subscription[] = [];
	areaSelect = 0;
	eventosNormalized: any;
	contentsBox: any[];
	projetosBox: any[];

	constructor(
		protected store: Store<AppState>,
		private dataService: DataService,
		protected notificacaoService: NotificacaoService,
		public global: GlobalService,
		private router: Router,
		private translate: TranslateService
	) {
		super(store, notificacaoService);
	}

	ngOnInit(): void {
		this.getProjetosHome();
		this.getEventosHome();
		this.getContentsHome();
		this.getState();
		this.setCurrentRoute();
		this.getNotificacoes();
		this.filteredCompetencias();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	getState() {
		this.stateSubscription = this.store
			.select((state: any) => {
				const { dadosEmpresa, dadosPolo, usuario, showingMenu, modalVideo, projetosAluno, conteudos, eventos } =
					state.newAppState;
				this.state = {
					dadosEmpresa,
					dadosPolo,
					usuario,
					showingMenu,
					modalVideo,
					projetosAluno,
					conteudos,
					eventos,
				} as AppState;
				this.usuario = this.state.usuario;

				this.contentsBox = [...(conteudos || [])];
				this.projetosBox = [...(projetosAluno || [])];
				if (this.state?.eventos?.eventosUser.length > 0) this.eventosNormalized = [...this.state.eventos.eventosUser];

				if (this.eventosNormalized && this.state?.eventos?.areaTipo.length > 0) {
					this.areaTipo = this.normalizedAreaTipo(this.state?.eventos?.areaTipo);
				}
			})
			.subscribe();
	}

	filteredCompetencias() {
		const filtered = [];

		if (this.state.usuario.usuarioCompetencia && this.state.usuario.usuarioCompetencia.length > 0) {
			this.quantidadeDeCompetencias = this.state.usuario.usuarioCompetencia.length;
			this.state.usuario.usuarioCompetencia.forEach((competencia) => {
				if (competencia.favorita) filtered.push(competencia);
			});
			this.store.dispatch(
				quantidadeCompetencias({
					payload: this.state.usuario.usuarioCompetencia.length,
				})
			);
		} else this.store.dispatch(quantidadeCompetencias({ payload: 0 }));
		this.filtereds = filtered;
	}

	setCurrentRoute() {
		sessionStorage.setItem('url', '/perfil');
		this.store.dispatch(fillLastRoute({ payload: '/perfil' }));
	}

	goToSocial(social) {
		if (this.state.usuario[social]) window.open(this.state.usuario[social]);
	}

	checkCloseModal(event) {
		if (event.toElement.nodeName == 'CLS-MODAL-VIDEO') this.store.dispatch(fillModalVideo({ payload: null }));
	}

	abrirMais(secao: string) {
		this.router.navigate([`perfil/${secao}/detalhes`]);
	}

	getProjetosHome() {
		const subscription = this.dataService
			.getSectionHome('projetos')
			.pipe(
				tap((projetos: any[]) => {
					this.dataService.verifyTrialAndDispatch(projetos);
					this.store.dispatch(fillProjetosAluno({ payload: projetos }));
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	getEventosHome() {
		const subscription = this.dataService
			.getSectionHome('eventos')
			.pipe(
				tap((eventos: any[]) => {
					this.store.dispatch(fillEventos({ payload: eventos }));
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	getContentsHome() {
		const subscription = this.dataService
			.getSectionHome('conteudos')
			.pipe(
				tap((conteudos: any[]) => {
					this.store.dispatch(fillConteudos({ payload: conteudos }));
				})
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	areaSelected(id: number) {
		this.areaSelect = id;
		this.eventosAreaTipo();
	}

	normalizedAreaTipo(tipos) {
		const eventArea = this.eventosNormalized.map((area: any) => area.area_tipo);

		const areaTipoNormalized = [];
		for (const area of tipos) {
			if (eventArea.includes(area.id_evento_area_tipo)) areaTipoNormalized.push(area);
		}

		return [{ id_evento_area_tipo: 0, nome: 'Todos' }, ...areaTipoNormalized];
	}

	eventosAreaTipo() {
		if (this.areaSelect == 0) this.eventosNormalized = this.state?.eventos?.eventosUser;
		if (this.areaSelect != 0)
			this.eventosNormalized = this.state?.eventos?.eventosUser.filter((x) => x.area_tipo == this.areaSelect);
	}
}
