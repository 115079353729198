<header>
	<div class="student">
		<div class="perfil-box" (click)="goToPerfil()">
			<div
				class="img-perfil"
				*ngIf="state?.usuario?.foto"
				[style.background-image]="global.generateImageURL(state?.usuario?.foto)"
			></div>
			<div
				class="img-perfil"
				*ngIf="!state?.usuario?.foto"
				[style.background-image]="global.generateImageURL('assets/imgs/profile-img.svg')"
			></div>
			<img class="medal" src="assets/imgs/medalha-header.png" />
			<div class="ml-3">{{ state?.usuario?.nome_social }}</div>
		</div>
	</div>
	<div class="menu">
		<div *ngIf="temProjetoAtivo" (click)="goToProject()" class="pointer menu-label upper">
			{{ 'HEADER.PROJECT' | translate }}
		</div>
		<div *ngIf="temProjetoAtivo" class="pointer menu-label material-didatico upper" (click)="goToMaterialDidatico()">
			{{ 'HEADER.TEACHING_MATERIAL' | translate }}
		</div>
		<!-- <div *ngIf="temProjetoAtivo" (click)="toggleAgendaVisibility()" class="pointer menu-label agenda-animation upper">{{ 'HEADER.CALENDAR' | translate }}</div> -->
		<div *ngIf="permiteInteracoes">
			<div
				*ngIf="temProjetoAtivo"
				(click)="toggleInteracoesVisibility()"
				class="pointer menu-label interacoes-animation upper"
			>
				{{ 'HEADER.INTERACTIONS' | translate }}
			</div>
		</div>
		<div class="notificacao-container">
			<button id="notificacao-button" class="notificacao-button" (click)="abrirNotificacao()">
				<img src="assets/icons/icone_notificacoes_white.svg" class="notificacao-img" />
			</button>
			<label
				class="notificacao-numero"
				[ngClass]="{
					'ajust-badge': notificacoes?.mensagensNaoLidas.length < 10
				}"
				for="notificacao-button"
				*ngIf="notificacoes?.mensagensNaoLidas.length > 0"
				>{{ notificacoes?.mensagensNaoLidas.length > 99 ? '99+' : notificacoes?.mensagensNaoLidas.length }}</label
			>
		</div>
		<div class="pointer menu-animation" (click)="toggleMenu()">
			<img class="buttons" src="assets/icons/new_menu.svg" data-cy="HeaderHomeImgMenu" />
			<div *ngIf="state?.notificationBadge" class="menu-badge"></div>
		</div>
	</div>
</header>
<app-menu *ngIf="state?.showingMenu"></app-menu>
<app-interacoes *ngIf="state?.showingInteracoes == 'open'"></app-interacoes>
<!-- <app-agenda *ngIf="state?.showingAgenda =='open'"></app-agenda> -->

<!--NOTIFICAÇÕES-->
<ui-sidebar-content
	titulo="{{ 'NOTIFICATIONS.TITLE' | translate }}"
	class="capitalize"
	(closeSidebar)="closeSidebar()"
	(marcarTodasNotificacoes)="marcarNotificacaoComoLida()"
	[showSidebar]="showNotificacao"
	[notificacoesNaoLidas]="notificacoesNaoLidas"
>
	<ui-card-data
		*ngFor="let mensagens of notificacoes?.mensagens | sortArrayDateNullFirst"
		[data]="mensagens[0].notificacao_usuario_data_envio"
	>
		<ui-mensagem-list
			*ngFor="let mensagem of mensagens | sortDateNullFirst : 'data_leitura'; let i = index"
			[mensagem]="mensagem"
			[ultimaMensagem]="mensagens.length - 1 === i"
			[mensagemLida]="!!mensagem.data_leitura"
			(marcarLido)="marcarComoLido($event)"
		></ui-mensagem-list>
	</ui-card-data>
	<p *ngIf="notificacoes?.mensagens.length === 0" class="capitalize">
		{{ 'NOTIFICATIONS.NONE' | translate }}
	</p>
</ui-sidebar-content>
<modal-material-didatico
	*ngIf="isShowingMaterial"
	[idProjetoAtivo]="idProjetoAtivo"
	(close)="closeModalEvent()"
></modal-material-didatico>
<!--NOTIFICAÇÕES-->
