import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { FasePDF, ProjetoPDF } from 'src/app/models';
import { DataService } from 'src/app/services/data-service/data.service';

@Component({
	selector: 'modal-material-didatico',
	templateUrl: './modal-material-didatico.component.html',
	styleUrls: ['./modal-material-didatico.component.scss'],
})
export class ModalMaterialDidaticoComponent implements OnInit, OnDestroy {
	@Input() idProjetoAtivo = 0;
	@Output() close = new EventEmitter();
	@ViewChild('pdfMaterial', { static: false }) pdfProject!: ElementRef;

	subscriptions: Subscription[] = [];

	logoUrl = 'assets/imgs/logo-liga-branca.png';
	contentPDF: ProjetoPDF = {} as ProjetoPDF;
	dateFormat = 'dd/MM/yyyy';
	isLoading = false;
	sizeA4: any = {
		vertical: 1414,
		horizontal: 1000,
	};
	entregaFinal!: FasePDF | undefined;
	styleSheetFile = 'assets/styles/modal-material-didatico.component.scss';
	constructor(private dataService: DataService, private translate: TranslateService) {
		const currentLang = this.translate.store.currentLang || this.translate.store.defaultLang;
		switch (currentLang.toLowerCase()) {
		case 'pt-br':
			this.dateFormat = 'dd/MM/yyyy';
			break;
		case 'en-us':
			this.dateFormat = 'MM/dd/yyyy';
			break;
		}
	}

	ngOnInit(): void {
		this.isLoading = true;
		const subscription = this.dataService
			.getMaterialDidatico(this.idProjetoAtivo)
			.pipe(
				tap((response: ProjetoPDF) => (this.contentPDF = response)),
				tap((response: ProjetoPDF) => {
					this.entregaFinal = response.projeto_fases.find((fase: FasePDF) => fase.fase_nome === 'ENTREGAFINAL');
				}),
				tap(() => {
					this.contentPDF.projeto_fases.forEach((fase: FasePDF) => {
						fase.fase_atividades.forEach((atividade) => {
							atividade.atividade_descricao = this.iframeReplace(atividade.atividade_descricao);
							atividade.atividade_descricao = this.fixFigureSize(atividade.atividade_descricao);
						});
					});
				}),
				finalize(() => (this.isLoading = false))
			)
			.subscribe();
		this.subscriptions.push(subscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	closeModel() {
		this.close.emit(true);
	}

	iframeReplace(conteudo = ''): string {
		if (!conteudo) return '';
		const regexIframe = /<iframe[^>]*src="[^>]*"[^>]*>[^>]*[<\/iframe>]/gm;

		const regexVideo = /<video[^>]*>[^>]*<\/video>/gm;

		const iframes = conteudo.match(regexIframe);
		let result = conteudo;
		if (iframes && iframes.length > 0)
			iframes.forEach((iframe) => {
				const urlMatch = iframe.match(/src="([^"]*)"/);
				let url = urlMatch ? urlMatch[0] : '';
				url = url.replace('src="', '').replace('"', '');
				const subst = this.getVideoString(url);
				result = result.replace(iframe, subst);
			});
		const videos = conteudo.match(regexVideo);
		if (videos && videos.length > 0)
			videos.forEach((video) => {
				const urlMatch = video.match(/src="([^"]*)"/);
				let url = urlMatch ? urlMatch[0] : '';
				url = url.replace('src="', '').replace('"', '');
				const subst = this.getVideoString(url);
				result = result.replace(video, subst);
			});

		result = result.replace(/<figure class="image"><img/gm, '<figure class="image"><img style="max-width: 100%;"');
		result = result.replace(/<p><img src="/gm, '<p><img style="max-width: 100%;" src="');
		result = result.replace(/<p> &nbsp; <img src/gm, '<p> &nbsp; <img style="max-width: 100%;" src');

		return result;
	}

	getVideoString(url = '') {
		const subst = `
	<style>
		@media print {
        	.videoAtividade {
          		-webkit-print-color-adjust: exact;
          		color-adjust: exact;
  			}
			::ng-deep .cls-img-full, .image {
				img {
					max-width: 100%;
					margin: 0 0 12px;
				}
			}
        }
	</style>
	<a style="font-family: var(--museo-regular); font-size: 10.37pt; color: var(--blue); text-align: center" href="${url}">
      <div class="videoAtividade">
        <div style="
        border-radius: 5px;
        border: solid 2px var(--blue);
        color: var(--blue);
        padding: 25px;
        font-family: var(--museo-medium);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        ">
          <div style="
          border-radius: 50%;
          width: 50px;
          height: 50px;
          background-color: var(--blue);
          display: flex;
          justify-content: center;
          align-items: center;
          ">
            <div style="
            display: inline-block;
            margin-right: -5px;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-bottom: 15px solid transparent;
            border-left: 25px solid #fff;
            ">
            </div>
          </div>
          <p style="margin: 0;text-align: center">${this.translate.instant('PROJECTS.VIDEO')}</p>
        </div>
        </a>
      </div>
    `;

		return subst;
	}

	fixFigureSize(conteudo = ''): string {
		const elValue = document.createElement('div');
		elValue.innerHTML = conteudo;

		if (conteudo) {
			this.appendAttribute(elValue, 'figure img', 'style', 'max-width: 100%');
			this.appendAttribute(elValue, 'p img', 'style', 'max-width: 100%');
		}

		return elValue.innerHTML;
	}

	appendAttribute(el: HTMLElement, elQuery: string, attrName: string, attrValue: string) {
		const linkEl = el.querySelectorAll(elQuery);
		linkEl.forEach((value) => {
			value.setAttribute(attrName, attrValue);
		});
	}
}
