import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/state';
import { fillFaseAtual } from 'src/app/state/state.actions';
import { GlobalService } from 'src/app/services/global-service/global.service';
import * as moment from 'moment';

@Component({
	selector: 'cls-home-box',
	templateUrl: './home-box.component.html',
	styleUrls: ['./home-box.component.scss'],
})
export class HomeBoxComponent implements OnInit, OnDestroy {
	@Input() data: any;
	@Input() selected: any;
	@Input() color: any;

	idFase: any;
	state: any;
	hasEntrega = false;
	stateSubscription$: any;
	startDate = '';
	endDate = '';

	constructor(private store: Store<AppState>, public global: GlobalService) {
		this.getState();
	}
	ngOnDestroy(): void {
		this.stateSubscription$.unsubscribe();
	}

	ngOnInit() {
		this.getState();
		this.setHasEntrega();
		this.setBoxDates();
	}

	setBoxDates() {
		switch (this.data.entregaTurma.length) {
		case 0:
			break;
		case 1:
			this.startDate = this.data.entregaTurma[0].data_entrega_inicio;
			this.endDate = this.data.entregaTurma[0].data_entrega;
			break;
		default:
			for (let i = 0; i < this.data.entregaTurma.length; i++) {
				if (
					this.data.entregaTurma[i].data_entrega_inicio &&
						(!this.startDate || moment(this.data.entregaTurma[i].data_entrega_inicio).isBefore(moment(this.startDate)))
				) {
					this.startDate = this.data.entregaTurma[i].data_entrega_inicio;
				}
				if (
					this.data.entregaTurma[i].data_entrega &&
						(!this.endDate || moment(this.data.entregaTurma[i].data_entrega).isAfter(moment(this.endDate)))
				) {
					this.endDate = this.data.entregaTurma[i].data_entrega;
				}
			}
			break;
		}

		if (this.startDate) this.startDate = moment(this.startDate).format('DD/MM/YYYY - HH:mm');
		if (this.endDate) this.endDate = moment(this.endDate).format('DD/MM/YYYY - HH:mm');
	}

	setHasEntrega() {
		const fase = this.state.fases.find((fase) => fase.id_fase_turma === this.data.id_fase_turma);
		if (fase?.arquivoEstudanteFaseTurma?.length > 0) this.hasEntrega = true;
		else this.dispatchFaseWithoutArquivo(fase);
	}

	dispatchFaseWithoutArquivo(faseTurma) {
		if (this.state.faseAtual) {
			const tempFase = this.state.faseAtual;
			if (faseTurma.id_fase_turma == tempFase.id_fase_turma && faseTurma?.arquivoEstudanteFaseTurma?.length == 0) {
				delete tempFase.arquivoEstudanteFaseTurma;
				this.store.dispatch(fillFaseAtual({ payload: tempFase }));
				if (this.state && this.state.fases)
					this.state.fases.forEach((fase) => {
						if (fase.id_fase_turma == tempFase.id_fase_turma) delete fase.arquivoEstudanteFaseTurma;
					});
			}
		}
	}

	selectBox() {
		if (
			this.state.estudante.id_projeto_turma_estudante_status == 8 ||
			(this.data.aberta && this.state && this.state.estudanteStatus !== 'trial')
		) {
			this.getState();
			this.scrollToContent();
		} else if (this.state && this.state.estudanteStatus === 'trial') {
			this.global.toast('Aviso!', 'Este recurso está disponível apenas para a versão completa');
		}
	}

	scrollToContent() {
		setTimeout(() => {
			window.scrollBy({
				top: 460,
				behavior: 'smooth',
			});
		}, 300);
	}

	getState() {
		this.stateSubscription$ = this.store
			.select((state: any) => {
				this.state = JSON.parse(JSON.stringify(state.newAppState));
			})
			.subscribe();
	}
}
